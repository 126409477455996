#unity-container { position: relative; text-align: center;}
/* #unity-container.unity-desktop { left: 50%; top: 50%; transform: translate(-50%, -50%) } */
#unity-container.unity-mobile { width: 100%; height: 100% }
#unity-canvas { background: #808080 }
.unity-mobile #unity-canvas { width: 100%; height: 100% }
#unity-loading-bar { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); display: none }
#unity-logo { width: 154px; height: 130px; background: url('Icon.PNG') no-repeat center }
#unity-progress-bar-empty { width: 141px; height: 18px; margin-top: 10px; background: url('progress-bar-empty-dark.png') no-repeat center }
#unity-progress-bar-full { width: 0%; height: 18px; margin-top: 10px; background: url('progress-bar-full-dark.png') no-repeat center }
#unity-footer { position: relative }
.unity-mobile #unity-footer { display: none }
#unity-webgl-logo { float:left; width: 204px; height: 38px; background: url('webgl-logo.png') no-repeat center }
#unity-build-title { float: right; margin-right: 10px; line-height: 38px; font-family: arial; font-size: 26px; font-weight: bold; }
#unity-fullscreen-button { float: center; width: 38px; height: 38px; background: url('fullscreen-button.png') no-repeat center }
#not-available {display: none;}
/* @media (max-width: 800px) {
    #not-available {display: block;}
    #unity-container {
        display: none;
    }
} */