html{
  width: 100%;
  height: 100%;
}
body{
  padding: 0;
  margin: 0;
  background-color: #003cffa2;
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
header{
  text-align: center;
  background-color: rgb(0, 0, 0);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.row{
  padding-top:5px;
}
button {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline:none;
}
.title{
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  text-decoration: underline;
}
.titleU {
  padding: 20px;
  text-align: center;
}
.sidenav{
  padding-left:15px;
  padding-bottom:10px;
}
.gameLabel{
  padding-top: 10px;
  color: white;
  font-weight: bold;
}
.gameLabel:hover{
  color: rgb(255, 228, 228);
  transform: scale(1.01);
}
.games{
  border: 6px solid white;
  border-radius: 100%;
  padding-top: 55px;
  padding-bottom: 60px;
  padding-left: 85px;
  padding-right: 45px;
  max-width: 350px;
  max-height: 300px;
  background-color: rgba(0, 0, 0, 0.836);
}
h1{
  font-weight: bold;
}
.games:hover{
  transform: scale(1.01);
}
.games:active{
  transform: scale(1.02);
}

footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  border-top: 1px solid #ffffff;
}
.contact-form {
  text-align: center;
  padding: 20px;
}
.login{
  padding-bottom: 10px;
}
#register{
  color: black;
  padding: 2px;
  padding-right: 5px;
  padding-left: 5px;
  max-width: 200px;
  border: 1px solid;
  background-color: rgb(228, 228, 228);
}
#recovery{
  color: black;
  padding: 2px;
  padding-right: 5px;
  padding-left: 5px;
  max-width: 200px;
  border: 1px solid;
  background-color: rgb(228, 228, 228);
}
.recovery{
  padding-top: 13px;
}
#register:hover{
  background-color: rgba(228, 228, 228, 0.904);
  max-width: 200px;
}
#register:active{
  background-color: rgba(228, 228, 228, 0.836);
  max-width: 200px;
}
.paymentBTN{
  padding-bottom: 10px;
}
.dashboard{
  padding: 20px;
}
#dashTitle {
  border: 5px solid;
}
.dashUnder {
  text-decoration: underline;
}
.dashForm {
  padding: 20px;
  border: 5px solid;
  background-color: rgb(88, 88, 88);
}
.footer-content{
  font-size: 10px;
  color: black;
}
#navFoot{
  color: rgb(53, 53, 53);
  font-weight: bold;
}
#navFoot:hover{
  color: rgb(153, 0, 0);
}
#navFoot:active{
  color: rgb(202, 0, 0);
}

#navHead{
  color: white;
}
#navSide{
  color: black;
  border-radius: 100%;
  padding: 20px;
  max-width: 200px;
  font-weight: bold;
  border: 3px solid #5e2626;
  background-color: rgb(228, 228, 228);
}
.nav-item:hover{
  transform: scale(1.05);
  max-width: 200px;
}
.nav-item:active{
  transform: scale(1.1);
  max-width: 200px;
}
.navbar-nav{
  padding-left: 10px;
}

#banner {
  min-height: 60px;
  border-bottom: 4px solid #5e2626;
}
  #banner h1 {
    color: #5e2626;
    font-size: 38px;
    font-weight: bold;
    z-index: 2;
    text-align: center; }
  #banner h3 {
    color: #5e2626;
    font-size: 22px;
    font-weight: bold;
    z-index: 2;
    text-align: center; }
  #banner nav .navbar-nav {
    max-width: 500px;
    margin: 0 auto; 
    font-size: 18px;
  }
  #banner nav .navbar-nav .nav-link {
    color: white;
    font-weight: bold;
    border-bottom: 2px solid transparent; }
  #banner nav .navbar-nav .nav-link:hover {
    border-bottom: 2px solid #5e2626; 
    background-color: #810202;
  }
  #banner nav .navbar-nav .nav-link.active {
    color: #cc0000;
    text-shadow: 3px 3px 3px #fff;
    border-bottom: 2px solid #5e2626;
    background-color: white;
    }
  .balance{
    padding-bottom: 5px;
    border-bottom: 4px solid #5e2626;
  }
  .balance1{
    padding-bottom: 1px;
    border-bottom: 2px solid white;
  }
  #sidenav-across {
    display: none;
  }
  #gamesSmall {
    display: none;
  }
  .checkbox {
    padding-top: 5px;
  }
  .emailChange {
    padding-right: 10px;
  }
/* .controls {
  text-align: left;
  padding-left: 150px;
} */
@media (max-width: 800px) {
  #sidenav-down {
    display: none;
  }
  #sidenav-across {
    display: block;
    font-size: 14px;
  }
  #sidenav-across .nav-item {
    padding: 5px;
  }
  #sidenav-across #navSide {
    padding: 22px;
  }
  #gamesSmall {
    padding-left: 15px;
    display: block;
    text-align: center;
  }
  #gamesLarge {
    display: none;
  }
  #payouts {
    padding-left: 10px;
  }
  /* .controls {
    text-align: left;
    padding-left: 10px;
  } */
}